import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

function Home() {
  return (
    <Layout>
      <SEO title="Home | Rendering Contractors" />
      <h1>Unsure what type of render to use?</h1>
      <p>
        Learn about the different types of render that are available and what
        suits your needs. <Link to="/render-types">Find out more</Link>
      </p>

      <h3>
        <Link to="/about">Why Choose Us?</Link>
      </h3>
      <p>
        Whatever the condition of your property, we have the experience and
        expertise to add charm and character to its exterior.
      </p>

      <h3>
        <Link to="/render-types">Types of Render</Link>
      </h3>
      <p>
        We offer a wide range of finishes and treatments that will enhance and
        improve the exterior of your property.
      </p>

      <h3>
        <Link to="/contact">Get a Quote</Link>
      </h3>
      <p>
        Do you have a specific project that you&rsquo;d like a quotation for?
        Want some more information about the services we offer?
      </p>

      <h2>Want to know more?</h2>
      <Link to="/contact">Get in touch</Link>
    </Layout>
  );
}

export default Home;
